import { Box } from 'components/Box/Box';
import { CenteredContent } from 'components/CenteredContent/CenteredContent';
import { IdealistLogo } from 'components/Logo/IdealistLogo';
import { Text } from 'components/Text/Text';
import { LoginFormContainer } from 'containers/LoginFormContainer';

type Props = {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLogin: (...args: Array<any>) => any;
  redirectTo: string | null | undefined;
  email: string | null | undefined;
  inviteId: string | null | undefined;
  savedListing?: { id: string; type: string; name: string };
  subscribedSubsite: string | null | undefined;
  title?: string;
};

export function LoginPage({
  onLogin,
  redirectTo,
  email,
  inviteId,
  savedListing,
  subscribedSubsite,
  title,
}: Props) {
  return (
    <CenteredContent>
      <Box textAlign="center">
        <IdealistLogo height={82} />
      </Box>
      <Text
        fontSize="24px"
        fontWeight="semiBold"
        color="mediumContentGrey"
        textAlign="center"
        mb="8px"
      >
        {title ||
          /* TRANSLATORS: login page title */
          getText('Log In')}
      </Text>
      <LoginFormContainer
        analyticsTitle="[main] Login page"
        usageContext="page"
        redirectTo={redirectTo}
        email={email}
        inviteId={inviteId}
        savedListing={savedListing}
        subscribedSubsite={subscribedSubsite}
        onLogin={onLogin}
        initialWarning={null}
      />
    </CenteredContent>
  );
}
