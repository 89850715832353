import { useCallback } from 'react';
import { useLocation } from 'react-router';

import type { ApiUser } from 'api/userEnvironment/types/ApiUserEnvironment';
import { useMultipleQueryParamStates } from 'hooks/useMultipleQueryParamStates';
import { useSetDocumentTitle } from 'hooks/useSetDocumentTitle';
import { useTrackPageView } from 'hooks/useTrackPageView';
import { allowRedirect } from 'utils/allowRedirect';

import { LoginPage } from './LoginPage';

export function LoginPageContainer() {
  const location = useLocation<{
    savedListing?: { id: string; type: string; name: string };
    subscribedSubsite?: string;
    title?: string;
    inviteId?: string;
    email?: string;
    redirectTo?: string;
  }>();

  const [stateFromQuery] = useMultipleQueryParamStates({
    names: ['invite', 'to'],
  });

  useSetDocumentTitle(location.state?.title);

  useTrackPageView('Login', {
    page_type: 'Login',
  });

  const handleRedirect = useCallback(
    (user: ApiUser) => {
      const inviteId = location.state?.inviteId;

      let redirectTo = null;

      if (inviteId) {
        redirectTo = `/user-invites/${inviteId}`;
      } else {
        redirectTo = stateFromQuery.to || location.state?.redirectTo;
      }

      if (!redirectTo || !allowRedirect(redirectTo)) {
        redirectTo = `/${user.locale}/`;
      }

      if (user.locale !== CURRENT_LOCALE()) {
        const regexp = new RegExp(`^/${CURRENT_LOCALE()}`);
        redirectTo = redirectTo.replace(regexp, `/${user.locale}`);
        window.location.assign(redirectTo);
      }

      // TODO: route relative urls with react-router once all pages have been redesigned
      window.location.assign(redirectTo); // this.props.history.push(redirectTo);
    },
    [stateFromQuery, location],
  );

  return (
    <LoginPage
      onLogin={handleRedirect}
      redirectTo={stateFromQuery.to || location.state?.redirectTo}
      email={location.state?.email}
      inviteId={stateFromQuery.invite || location.state?.inviteId}
      savedListing={location.state?.savedListing}
      subscribedSubsite={location.state?.subscribedSubsite}
      title={location.state?.title}
    />
  );
}
